/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-heading .section-heading-lower {
  font-size: 2.5rem;
}

/*#root {*/
/*  background: url('https://images.unsplash.com/photo-1530104091755-015d31dfa0b9');*/
/*  background-size: cover;*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*}*/