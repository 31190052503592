.themes {
  text-align: center;
  margin: auto;
  background-color: #9079be26;
}

@media (min-width: 800px) {
  .themes {
    /*background-color: #9079be26;*/
  }
}

@media (max-width: 800px) {
  .themes {
    .border.border-success {

      /*border: 0 solid !important*/
    }

  }
}

.margin-auto {
  margin: auto;
}

.fill-parent {
  height: 100%;
}

.fixed-line {
  line-height: 1.4;
}
